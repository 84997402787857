@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "PT Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: "PT Sans", sans-serif;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.contributorButton{-webkit-transform:translate(200%);transform:translate(200%)}.orderButtons{float:right;margin-top:auto}.buttonGroup{max-width:0}.errorLogoutButton{margin:0 auto;display:block}.selected{color:cornflowerblue}.select__placeholder.css-1wa3eu0-placeholder{color:#595959}

.login{text-align:center}.login-grid{min-height:50vh}.login-card{width:300px;height:150px}.feide-login-button{font-weight:bold}.feide-login-icon{height:50px}.cristin-logo{width:500px}.MuiPaper-root.MuiPaper-elevation1.MuiCard-root.jss190.MuiPaper-rounded{overflow-x:visible;overflow-y:visible}.MuiPaper-root.MuiPaper-elevation1.MuiCard-root.makeStyles-card-190.MuiPaper-rounded{overflow-x:visible;overflow-y:visible}.contributor-suggestions .author{color:#3c8947;margin-top:10px;margin-left:10px}.contributor-suggestions .affiliations{font-style:italic;margin-left:10px}.contributor-suggestions .select-button{justify-self:flex-end}.card-horiz.result{border-color:#76559a}.card-horiz.contributor{border-color:#3c8947}.card-horiz{display:flex;width:100%;border-left:6px solid #424242;margin-bottom:15px;padding:0;word-wrap:break-word}.basic-background{background-color:#f5f5f5}.metadata{color:black;font-size:13px}.no-padding{padding:0}.contributorModal{width:80%;max-width:80%}.result{padding:1em}.result.result{color:#76559a;padding:0.2em}.result.contributor{color:#3c8947}.result:hover{text-decoration:none}.result .image-wrapper{float:left;height:100%}.result .image-wrapper img{max-width:40px;max-height:40px}.result .image-wrapper.person{margin-top:20px}.result .content-wrapper{margin:0 4em 0 4em}.result h3{margin-bottom:0.5em}.result p{margin-bottom:0;margin-top:0}.result .active{font-weight:bold}.result .italic{font-style:italic}.card-search{padding-top:10px;text-align:center}.duplicate-search-grid{margin-left:20px;margin-right:20px;margin-bottom:20px}

.login{text-align:center}.login-grid{min-height:50vh}.login-card{width:300px;height:150px}.feide-login-button{font-weight:bold}.feide-login-icon{height:50px}.cristin-logo{width:500px}.MuiPaper-root.MuiPaper-elevation1.MuiCard-root.jss190.MuiPaper-rounded{overflow-x:visible;overflow-y:visible}.MuiPaper-root.MuiPaper-elevation1.MuiCard-root.makeStyles-card-190.MuiPaper-rounded{overflow-x:visible;overflow-y:visible}.contributor-suggestions .author{color:#3c8947;margin-top:10px;margin-left:10px}.contributor-suggestions .affiliations{font-style:italic;margin-left:10px}.contributor-suggestions .select-button{justify-self:flex-end}

.MuiInputBase-input.Mui-disabled {
  opacity: 1;
  font-weight: bold;
}

.journalError {
  color: red;
  font-size: 0.80rem;
  padding-top: 5px;
  padding-bottom: 10px;
}
.result-title {
  color: #76559a;
  font-weight: bold;
}

.journal-name {
  font-style: italic;
}

.focused {
  background-color: lightgray;
}

.header-title {
  font-family: PT Sans, sans-serif;
  font-weight: bolder;
  font-size: x-large;
}

.footer-title {
  font-size: medium;
  font-weight: lighter;
}

