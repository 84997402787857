.MuiInputBase-input.Mui-disabled {
  opacity: 1;
  font-weight: bold;
}

.journalError {
  color: red;
  font-size: 0.80rem;
  padding-top: 5px;
  padding-bottom: 10px;
}