.header-title {
  font-family: PT Sans, sans-serif;
  font-weight: bolder;
  font-size: x-large;
}

.footer-title {
  font-size: medium;
  font-weight: lighter;
}
