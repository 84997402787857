.contributorButton {
  transform: translate(200%);
}

.orderButtons {
  float: right;
  margin-top: auto;
}

.buttonGroup {
  max-width: 0;
}

.errorLogoutButton {
  margin: 0 auto;
  display: block;
}

.selected {
  color: cornflowerblue;
}

.select__placeholder.css-1wa3eu0-placeholder {
  color: rgb(89, 89, 89);
}
