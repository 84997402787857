@import "./common";

.card-horiz {
  &.result {
    border-color: $color-purple;
  }

  &.contributor {
    border-color: $color-green;
  }
}

.card-horiz {
  display: flex;
  width: 100%;
  border-left: 6px solid #424242;
  margin-bottom: 15px;
  padding: 0;
  word-wrap: break-word;
}

.basic-background {
  background-color: #f5f5f5;
}

.metadata {
  color: black;
  font-size: 13px;
}

.no-padding {
  padding: 0;
}

.contributorModal {
  width: 80%;
  max-width: 80%;
}

.result {
  padding: 1em;

  &.result {
    color: $color-purple;
    padding: 0.2em;
  }

  &.contributor {
    color: $color-green;
  }

  &:hover {
    text-decoration: none;
  }

  .image-wrapper {
    float: left;
    height: 100%;
    img {
      max-width: 40px;
      max-height: 40px;
    }

    &.person {
      margin-top: 20px;
    }
  }

  .content-wrapper {
    margin: 0 4em 0 4em;
  }

  h3 {
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 0;
    margin-top: 0;
  }

  .active {
    font-weight: bold;
  }

  .italic {
    font-style: italic;
  }
}

.card-search {
  padding-top: 10px;
  text-align: center;
}

.duplicate-search-grid {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}